<template>
  <ul class="leftMenu">
    <li
        v-for="(item,index) in menuList"
        :key="index"
        :class="{ active: activeIndex==index }"
        @click="updateActiveIndex(item)"
    >
      <span>{{ index != 0 ? `${item.name}` : item.name }}</span>
      <i class="el-icon-arrow-right"></i>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      menuList: this.list,
      activeIndex: null
    }
  },
  mounted() {
    if (this.$route.query.menu != undefined) {
      this.menuList.forEach((item, index) => {
        if (item.id == this.$route.query.menu) {
          this.activeIndex = index;
          this.$forceUpdate();
        }
      })
    } else {
      this.$api.articleList().then((res) => {
        this.menuList = res.data;
      });
    }
  },
  methods: {
    updateActiveIndex(val) {
      this.$fun.routerToPage(`/answerResultPage?menu=${val.id}`);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";

.leftMenu {
  padding: 10px;
  width: 215px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
    cursor: pointer;
  }

  li.active {
    color: #B4272B;
  }
}
</style>
