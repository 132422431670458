<template>
  <div class="rightBox">
    <div class="showBodyBoxTitle">
      {{ menuInfo.name }}
    </div>
    <div class="showBodyBoxAnswer">
      <ul class="pageListLink">
        <li v-for="(item,index) in menuInfo.qa_article" :key="index"
            @click="$fun.routerToPage(`/answerResultPage?menu=${menuInfo.id}&problemId=${item.id}`)">{{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    menuInfo: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.pageListLink {
  li {
    margin-bottom: 20px;
    color: #B4272B;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
  }
}
</style>