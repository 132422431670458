<template>
  <div>
    <Head/>
    <searchModel/>
    <div class="don">
      <div class="secconBox">
        <div class="titleBox">
          <span class="pageNameSpan" @click="$fun.routerToPage('/questionAndAnswer')">{{ $fanyi('帮助中心') }}</span>
          <i class="el-icon-arrow-right"></i>
          <span class="blurFont" @click="$fun.routerToPage(`/answerResultPage?menu=${$route.query.menu}`)">{{
              menuName
            }}</span>
          <i v-if="$route.query.problemId!=undefined" class="el-icon-arrow-right"></i>
          <span v-if="$route.query.problemId!=undefined" class="blurFont overflow">{{ problemTitle }}</span>
        </div>
        <div class="bodyBox">
          <leftMenu v-if="leftMenuShow" :list="menuList"/>
          <component :is="componentName" :menuInfo="menuInfo"/>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import searchModel from "./components/searchModel.vue";
import leftMenu from "@/views/footList/questionAndAnswer/components/leftMenu.vue";
import articleList from "@/views/footList/questionAndAnswer/components/articleList.vue";
import problemDetails from "@/views/footList/questionAndAnswer/components/problemDetails.vue";
import Head from "@/components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";

export default {
  components: {Foot, Head, searchModel, leftMenu, articleList, problemDetails},
  data() {
    return {
      menuList: [],
      menuName: '',
      leftMenuShow: false,
      problemTitle: '',
      menuInfo: {},
      componentName: 'articleList'
    }
  },
  created() {
    this.$api.articleList().then((res) => {
      res.data.forEach((item) => {
        if (this.$route.query.menu != undefined && this.$route.query.menu == item.id) {
          this.menuName = item.name;
          this.menuInfo = item;
        }
        if (this.$route.query.problemId != undefined) {
          this.componentName = 'problemDetails'
          item.qa_article.forEach((articleItem) => {
            if (articleItem.id == this.$route.query.problemId) {
              this.problemTitle = articleItem.title;
              this.menuInfo = articleItem;
            }
          })
        }
      })
      this.menuList = res.data;
      this.leftMenuShow = true
    });
  }
}
</script>

<style lang="scss" scoped>
.don {
  background-color: white;
  padding-bottom: 1px;
  margin-bottom: 30px;

  .blurFont {
    color: #B4272B;
    cursor: pointer;
  }

  .secconBox {
    width: 1000px;
    margin: 0 auto;

    .titleBox {
      padding: 30px 10px 24px;
      font-size: 14px;

      .pageNameSpan {
        color: #7a7a7a;
        cursor: pointer;
      }

      .el-icon-arrow-right {
        margin: 0 10px;
        color: #1a289d;
      }
    }

    .bodyBox {
      margin: 20px 0 80px;
      display: flex;
      align-items: flex-start;

      hr {
        margin: 20px 0;
        background-color: #d5d5d5;
        width: 100%;
      }

      .dianzi {
        height: 14px;
      }
    }

    .rightBox {
      flex: 1;

      padding: 10px;

      .btnContainer {
        display: flex;
        justify-content: flex-end;

        div {
          width: 100px;
          height: 30px;
          border-radius: 8px;
          background: #B4272B;
          line-height: 30px;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .rightBox.thred {
      max-width: 738px;
    }

    /deep/ .showBodyBoxTitle {
      font-size: 26px;
      font-weight: 500;
      line-height: 1.4em;
      margin-bottom: 20px;
    }

    /deep/ .titleFontThree {
      .showBodyBoxAnswer {
        font-size: 15px;
        font-weight: 400;
        line-height: 2em;
      }
    }

    /deep/ .showBodyBoxAnswer {
      font-size: 14px;
      line-height: 1.5;
      color: #404040;
    }
  }
}
</style>