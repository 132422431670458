<template>
  <div class="rightBox thred">
    <div class="showBodyBoxTitle" v-html="menuInfo.title"></div>
    <div class="dianzi"></div>
    <div v-html="menuInfo.content"></div>
    <div class="btnContainer">
      <div @click="$router.go(-1)">戻る</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    menuInfo: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.rightBox {
  flex: 1;

  padding: 10px;

  .showBodyBoxTitle {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.4em;
    margin-bottom: 20px;
  }

  .btnContainer {
    display: flex;
    justify-content: flex-end;

    div {
      width: 100px;
      height: 30px;
      border-radius: 8px;
      background: #B4272B;
      line-height: 30px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}

/deep/ .allFontPage {
  p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 14px;
    line-height: 2em;
  }
}

.rightBox.thred {
  max-width: 738px;
}
</style>